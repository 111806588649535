










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class IconOverlay extends Vue {
  @Prop({ type: Boolean, default: false }) isOpen: boolean;
}
